import React, { useEffect } from 'react';

import { useGameState } from '../../../provider/GameStateProvider';

const DGameWelcomeScreen = () => {

    const [ gameState ] = useGameState();

    useEffect(()=>{
        window.gtag('event', 'screen_view', {
            'event_category' : 'screens',
            'event_label' : 'Instructions'
        });
    },[])

    return (
        <div className="px-4 py-6 cover text-center overflow-y-auto pos-rel">
            <img className="pos-abs z-1" style={{top:-4, right:10}} src={ gameState.images.pga_logo } width={420} />
            <div className="px-4 pt-7 z-0 bg-primary d-flex align-items-start justify-content-center " style={{ gap:2, borderRadius:4, margin:10 }}>
                <div className="pos-rel bg-white text-dark-violet px-4 pt-2 pb-1" style={{width:870, borderRadius:20}}>
                    <div className="text-left d-flex cover flex-column w-100">
                        <div className="pos-rel py-3 d-flex align-items-start" style={{ gap: 10 }}>
                            <div className="pos-rel" style={{flex:'0 0 100px'}}>
                                <img style={{position:'absolute', transform:'translateY(-10px)', width:'100%'}} src={ gameState.images.instructions[1] } />
                            </div>
                            <div className="d-flex align-items-center bg-violet justify-content-center" style={{flex:'0 0 40px', borderRadius:20, width:40, height:40}}>
                                <h5 className="bold text-white" style={{marginTop:3}}>1</h5>
                            </div>
                            <div className="pl-1">
                                <h5 className="medium">Hold your phone like a golf club and press the<br />&quot;Reset&nbsp;Swing&nbsp;Position&quot; button.</h5>
                            </div>
                            <div style={{flex:'0 0 200px'}}></div>
                        </div>
                        <div className="pos-rel py-3 d-flex align-items-start" style={{gap:10}}>
                            <div style={{flex:'0 0 100px'}}></div>
                            <div className="d-flex align-items-center bg-violet justify-content-center" style={{flex:'0 0 40px', borderRadius:20, width:40, height:40}}>
                                <h5 className="bold text-white" style={{marginTop:1}}>2</h5>
                            </div>
                            <div className="pl-1 pr-3 flex-grow-1">
                                <h5 className="medium">Take some practice swings. Like in real golf,<br />power comes from the speed of your forward <br />swing, not how aggressively you swing.</h5>
                            </div>
                            <div className="pos-rel ml-n3" style={{flex:'0 0 240px'}}>
                                <img style={{position:'absolute', transform:'translateY(-40px)', width:'100%'}} src={ gameState.images.instructions[2] } />
                            </div>
                        </div>
                        <div className="pos-rel py-3 d-flex align-items-start" style={{gap:10}}>
                            <div className="pos-rel" style={{flex:'0 0 100px'}}>
                                <img style={{position:'absolute', transform:'translateY(-40px)', width:'100%'}} src={ gameState.images.instructions[3] } />
                            </div>
                            <div className="d-flex align-items-center bg-violet justify-content-center" style={{flex:'0 0 40px', borderRadius:20, width:40, height:40}}>
                                <h5 className="bold text-white" style={{marginTop:1}}>3</h5>
                            </div>
                            <div className="pl-1">
                                <h5 className="medium">Press the &quot;Ready&nbsp;to&nbsp;Hit&quot; button when you&apos;re<br />ready to take your real swing.</h5>
                            </div>
                            <div style={{flex:'0 0 200px'}}></div>
                        </div>
                        <div className="pos-rel py-2 d-flex align-items-start">
                            <div style={{flex:'0 0 160px'}}></div>
                            <ul style={{paddingLeft:23}}>
                                <li className="mb-1"><h5 className="medium">Take note of the wind speed and direction and adjust your aim accordingly.</h5></li>
                                <li><h5 className="medium">The line on the mini map shows how hard your ball would go under ideal conditions and the hash marks indicate the power shown on your power meter.</h5></li>
                            </ul>
                            <div style={{flex:'0 0 150px'}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DGameWelcomeScreen