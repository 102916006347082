import React, { useEffect } from 'react';

import { useGameState } from '../../../provider/GameStateProvider';

const DGameOverScreen = () =>{

    const [ gameState ] = useGameState();

    useEffect( () => {
        
        window.gtag('event', 'screen_view', {
            'event_category' : 'screens',
            'event_label' : 'Game Over'
        } );
        
        setTimeout( () => window.open( '/', '_self' ), 90000 )
    }, [] )

    const score_messages = [ 'Good Effort!', 'Well Done!', 'You’re a Champion!' ]

    const getScoreMessage = ( s ) => {

        if( s <= 100 ) return score_messages[0];

        if( s <= 200 ) return score_messages[1];

        return score_messages[2]
    }

    return (
        <div className="cover text-center d-flex flex-column overflow-y-auto">
            <div className="pos-rel pt-4 d-flex flex-column" style={{minHeight:'100%'}}>
                <img className="pos-abs z-1" style={{top:-4, right:10}} src={ gameState.images.pga_logo } width={420} />
                <div className="text-white d-flex flex-column align-items-center justify-content-center" style={{ gap: 20, paddingTop:200, paddingBottom:150 }}>
                    <h4 className="medium lh-1">{ `${ getScoreMessage( Number( gameState.totalScore ) ) } You scored` }</h4>
                    <h1 className="bold bg-orchid" style={{padding:'10px 40px 6px 40px', borderRadius:50}}>{ gameState.totalScore }</h1>
                    <h4 className="medium lh-1">out of 300 points.</h4>
                    <h4 className="medium lh-1">Thank you for playing.</h4>
                </div>
                <div className="flex-grow-1 d-flex flex-column text-violet align-items-center bg-white" style={{gap:10}}>
                    <div className="w-100 bg-white p-2">
                        <h3 className="bold text-violet" style={{padding:'10px 40px 6px 40px'}}>One great swing can<br />move the game forward.</h3>
                        <p className="text-black" style={{maxWidth:600, margin:'10px auto 0 auto'}}>As the Official Life Insurance and Retirement partner of the PGA of America, Corebridge Financial is dedicated to supporting PGA Professionals on the course. But we’re also dedicated to supporting them off the course by helping them save for retirement and achieve financial security.</p>
                    </div>
                    <div className="w-100 bg-white flex-grow-1 p-2">
                        <h4 className="bold text-violet" style={{padding:'0 40px 6px 40px'}}>Learn more about Corebridge Financial<sup style={{fontSize:14}}>®</sup></h4>
                        <div className="d-flex justify-content-center pt-2 pb-1" style={{gap:10}}>
                            <a style={{ borderRadius:40, textDecoration:'none' }} className="small medium text-white bg-violet px-3 py-2 d-inline-block" href="https://view.ceros.com/aig/movingfinancialfuturesforward/p/1">View our brochure</a>
                            <a style={{ borderRadius:40, textDecoration:'none' }} className="small medium text-white bg-violet px-3 py-2 d-inline-block" href="/">Play again</a>
                            <a style={{ borderRadius:40, textDecoration:'none' }} className="small medium text-white bg-violet px-3 py-2 d-inline-block" href="http://www.corebridgefinancial.com/pga">Visit the website</a>
                        </div>
                    </div>
                    <div className="bg-light-gray px-4 pt-3 w-100">
                        <div className="w-100 pb-2 text-left text-dark-gray pr-4">
                            <p style={{fontSize:10}}>© 2023. Corebridge Financial, Inc. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default DGameOverScreen
