import React, { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router';

import { useSocketState } from '../../../provider/SocketProvider';

import { useGameState } from '../../../provider/GameStateProvider';

const DHomeScreen = () => {

    const navigate = useNavigate();

    const [ socket, socketReady ] = useSocketState();

    const [ gameState, gameDispatch ] = useGameState();

    const location = useLocation();

    useEffect( () => {
        
        console.log( "Game State Changed PGA" );

        console.log( gameState );

    }, [ gameState ] );

    useEffect( () => {

        if( socketReady == true ){

            socket.addEventListener( 'message', function ( event ) {

                let data = JSON.parse( event.data );

                if( data.message == 'welcome' ){

                    if( location.pathname == '/' ) navigate( '/game', {replace:true} );
                }

                if( data.message == 'rules' ){

                    if( location.pathname == '/' ) navigate( '/game/rules', {replace:true} );
                }

                if( data.message == 'character' ){

                    navigate( '/game/character', {replace:true} );
                }

                if( data.message == 'play' ){

                    navigate( '/game/play', {replace:true} );
                }

                if( data.message.character ){
                    
                    gameDispatch( { type: 'setCharacter', character: data.message.character } );
                }

                if( data.message == 'startover' ){

                    navigate('/');
                }

                if( data.message == 'userconnected' ){

                    console.log('userconnected');

                    gameDispatch( { type: 'setPhoneConnected', value:true } );
                }

                if( data.message == 'userdisconnected' ){

                    console.log('user disconnected');

                    gameDispatch( { type: 'setPhoneConnected', value:false } );
                }
            } );
        }

    }, [ socketReady ] )

    return (
        <div className="px-4 cover d-flex bg-cover pos-rel justify-content-center" style={{backgroundImage:`url('${ gameState.images.home_bg }')`}}>
            <img className="pos-abs z-1 hide-mobile" style={{top:-4, right:10}} src={ gameState.images.pga_logo } width={420} />
            <img className="pos-abs z-1 show-mobile" style={{top:10, left:34}} src={ gameState.images.pga_logo } width={320} />
            <div className="d-flex flex-column align-items-center text-white justify-content-center cover" style={{gap:40}}>
                <div className="d-flex flex-column align-items-center text-white hide-mobile" style={{gap:20}}>
                    <h5 className="regular mt-n4">TAKE ACTION</h5>
                    <div className="bg-white d-flex align-items-center justify-content-center" style={{borderRadius:20, overflow:'hidden'}}>
                        <img width={280} src={ gameState.session.qrcode } />
                    </div>
                    <h6 className='text-center'>Scan the code and take a swing<br />at our interactive golf game</h6>
                </div> 
                <div className="show-mobile px-1 text-left">
                    <h4 className="regular my-3">Please visit this page on your laptop or tablet to begin.</h4>
                </div>
            </div>
            <div className="pos-abs bl-0 p-4 text-white" style={{fontSize:11}}>
                <p className="mb-1">Please connect your phone to Wi-Fi for the best experience.</p>
            </div>
        </div>
    )
}

export default DHomeScreen
